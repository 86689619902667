/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-04 17:31:52
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-04 18:39:20
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/RealTimeData/list.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const valueList = [
  {
    name: '流量请求',
    value: 'request',
    decimals: 0,
    ratio: 'requestRatio'
  },
  {
    name: '流量填充率',
    value: 'requestFilledRate',
    decimals: 2,
    ratio: 'requestFilledRateRatio'
  },
  {
    name: '广告请求',
    value: 'advRequest',
    decimals: 0,
    ratio: 'advRequestRatio'
  },
  {
    name: '广告展示',
    value: 'impress',
    decimals: 0,
    ratio: 'impressRatio'
  },
  {
    name: '广告点击',
    value: 'click',
    decimals: 0,
    ratio: 'clickRatio'
  },
  {
    name: '广告收入',
    value: 'advPrice',
    decimals: 2,
    ratio: 'advPriceRatio'
  },
  {
    name: '流量成本',
    value: 'income',
    decimals: 2,
    ratio: 'incomeRatio'
  }
]
